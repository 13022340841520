import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import OtherRoutes from './OtherRoutes';

// oomol code start
import LoginRoutes from './LoginRoutes';
// oomol code end

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // oomol code start
  return useRoutes([LoginRoutes, MainRoutes, OtherRoutes]);
  // oomol code end
}
