import { useSelector } from 'react-redux';
import { useEffect, useContext, useState } from 'react';
import { UserContext } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { API } from 'utils/api';
import { LOGIN } from 'store/actions';

const AuthGuard = ({ children }) => {
  const account = useSelector((state) => state.account);
  const { isUserLoaded } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // oomol code start
  const [isProfileFetched, setIsProfileFetched] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = localStorage.getItem('user');
      if (user) {
        setIsProfileFetched(true);
        return;
      }

      try {
        const res = await API.get("/api/user/self");
        if (res.status === 200) {
          const { data } = res;
          localStorage.setItem('user', JSON.stringify(data.data));
          dispatch({ type: LOGIN, payload: data.data });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsProfileFetched(true);
      }
    };

    fetchUserProfile();
  }, [dispatch]);
  // oomol code end

  useEffect(() => {
    if (isUserLoaded && isProfileFetched && !account.user) {
      // oomol code start
      navigate('/login');
      // oomol code end
      return;
    }
  }, [account, navigate, isUserLoaded, isProfileFetched]);

  return children;
};

export default AuthGuard;
