export const ITEMS_PER_PAGE = 10; // this value must keep same as the one defined in backend!

// oomol code start
export const getOomolServerBaseUrl = () => {
  switch (window.location.hostname) {
    case "console.oomol.com":
      return "https://api.oomol.com";
    case "console.oomol.dev":
      return "https://api.oomol.dev";
    case "localhost":
      return process.env.NODE_ENV === "local"
        ? "http://localhost:8080"
        : "https://api.oomol.dev";
    default: // localhost
      throw new Error("Unknown hostname");
  }
};
// oomol code end
