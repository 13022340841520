import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
// import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';


// login option 3 routing
const AuthRegister = Loadable(lazy(() => import('views/Authentication/Auth/Register')));
// oomol code start

// const AuthLogin = Loadable(lazy(() => import('views/Authentication/Auth/Login')));
// const GitHubOAuth = Loadable(lazy(() => import('views/Authentication/Auth/GitHubOAuth')));
// const LarkOAuth = Loadable(lazy(() => import('views/Authentication/Auth/LarkOAuth')));
// const OidcOAuth = Loadable(lazy(() => import('views/Authentication/Auth/OidcOAuth')));
// const ForgetPassword = Loadable(lazy(() => import('views/Authentication/Auth/ForgetPassword')));
// const ResetPassword = Loadable(lazy(() => import('views/Authentication/Auth/ResetPassword')));
// const Home = Loadable(lazy(() => import('views/Home')));
// const About = Loadable(lazy(() => import('views/About')));

// oomol code end
const NotFoundView = Loadable(lazy(() => import('views/Error')));

const Dashboard = Loadable(lazy(() => import('views/Dashboard')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const OtherRoutes = {
  path: '/',
  // oomol code start
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <Dashboard />
    },

    // {
    //   path: '/about',
    //   element: <About />
    // },
    // {
    //   path: '/login',
    //   element: <AuthLogin />
    // },
    {
      path: '/register',
      element: <AuthRegister />
    },
    // {
    //   path: '/reset',
    //   element: <ForgetPassword />
    // },
    // {
    //   path: '/user/reset',
    //   element: <ResetPassword />
    // },
    // {
    //   path: '/oauth/github',
    //   element: <GitHubOAuth />
    // },
    // {
    //   path: '/oauth/lark',
    //   element: <LarkOAuth />
    // },
    // {
    //   path: 'oauth/oidc',
    //   element: <OidcOAuth />
    // },

    // oomol code end
    {
      path: '/404',
      element: <NotFoundView />
    }
  ]
};

export default OtherRoutes;
