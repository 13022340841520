import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';

const Login = Loadable(lazy(() => import('views/Login')));

const LoginRoutes = {
    path: "/login",
    element: <Login />,
}

export default LoginRoutes;
