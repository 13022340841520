import { API } from 'utils/api';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'store/actions';
import { useNavigate } from 'react-router';
import { showSuccess } from 'utils/common';
import {getOomolServerBaseUrl} from "../constants"

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = async (username, password) => {
    try {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        localStorage.setItem('user', JSON.stringify(data));
        dispatch({ type: LOGIN, payload: data });
        navigate('/panel');
      }
      return { success, message };
    } catch (err) {
      // Request failed，Settings错误信息
      return { success: false, message: '' };
    }
  };

  const githubLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/github?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('Binding successful!');
          navigate('/panel');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess('Login successful!');
          navigate('/panel');
        }
      }
      return { success, message };
    } catch (err) {
      // Request failed，Settings错误信息
      return { success: false, message: '' };
    }
  };

  const larkLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/lark?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('Binding successful!');
          navigate('/panel');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess('Login successful!');
          navigate('/panel');
        }
      }
      return { success, message };
    } catch (err) {
      // Request failed，Settings错误信息
      return { success: false, message: '' };
    }
  };

  const oidcLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/oidc?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('Binding successful!');
          navigate('/panel');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess('Login successful!');
          navigate('/panel');
        }
      }
      return { success, message };
    } catch (err) {
      // Request failed，Settings错误信息
      return { success: false, message: '' };
    }
  }

  const wechatLogin = async (code) => {
    try {
      const res = await API.get(`/api/oauth/wechat?code=${code}`);
      const { success, message, data } = res.data;
      if (success) {
        dispatch({ type: LOGIN, payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        showSuccess('Login successful!');
        navigate('/panel');
      }
      return { success, message };
    } catch (err) {
      // Request failed，Settings错误信息
      return { success: false, message: '' };
    }
  };

  // oomol code start
  // 后端返回的Status码是 302，但是前端并不会跳转，参考：https://medium.com/@yangcar/misunderstanding-of-301-302-api-response-a89fe891be6a
  // 我们也不需要跳转，只要能让User loginStatus失效就可以，未来如果需要跳转再改 host 参数
  // 由于该请求没有返回值且 header 特殊，所以特殊处理
  const logoutPost = async () => {
    const headers = new Headers();
    headers.set("accept", "application/json, text/plain, */*");
    const config = {
      method: "POST",
      mode: "no-cors",
      headers,
      credentials: "include",
      body: JSON.stringify({
        host: window.location.host,
      })
    }
    headers.set("content-type", "application/json");

    await fetch(
      `${getOomolServerBaseUrl()}/v1/auth/logout`,
      config
    );
    return;
  }
  // oomol code end

  const logout = async () => {
    // oomol code start
    // https://github.com/axios/axios/issues/1358#issuecomment-364789526 axios None法Settings mode，但是我们需要Settings mode : no-cors, 所以我们用 fetch
    await logoutPost();
    // oomol code end
    localStorage.removeItem('user');
    dispatch({ type: LOGIN, payload: null });
    navigate('/login');
  };

  return { login, logout, githubLogin, wechatLogin, larkLogin,oidcLogin };
};

export default useLogin;
